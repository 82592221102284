<template>
  <v-container class="pa-0" fluid>
    <kurcc-site-list/>
  </v-container>
</template>

<script>

export default {
  name: 'KurccSitesPage',
  components: {
    KurccSiteList: () => import('@/modules/more/components/KurccSiteList')
  }
}
</script>
